export const getCookie = (key: string): string => {
  const cookies = document.cookie
    .split('; ')
    .reduce((acc: Record<string, string>, cookie: string) => {
      const [name, value] = cookie.split('=')
      acc[name] = decodeURIComponent(value)
      return acc
    }, {})
  return cookies[key] || ''
}

export const setCookie = (key: string, value: string, days = 365): void => {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000) // One year expiration by default
  document.cookie = `${key}=${encodeURIComponent(
    value,
  )};expires=${expires.toUTCString()};path=/;Secure'`
}

export const clearAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    const name = cookie.split('=')[0].trim()
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`
  })
}
