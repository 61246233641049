import { useCookie } from '../useCookie'

const generateUUID = (): string => {
  if (crypto.randomUUID) {
    return crypto.randomUUID()
  }
  // Fallback for older browsers
  const array = new Uint8Array(16)
  crypto.getRandomValues(array)
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('')
}

export function getChannelAndZone(url: string) {
  const [network, channel, zone] = url.split('/').slice(-3)

  if (network !== 'network') {
    return { channel: 'mock-channel', zone: 'mock-zone' }
  }

  return { channel, zone }
}

const LEAD_UUID_COOKIE_KEY = 'cnf-embedded-lead-uuid'

export function getCookieKey({ channel, zone }: { channel: string; zone: string }) {
  return `${LEAD_UUID_COOKIE_KEY}__${channel}/${zone}`
}

export function useLeadUUID(url: string) {
  const { cookie: leadUUID } = useCookie(
    getCookieKey(getChannelAndZone(url)),
    generateUUID(),
  )

  return { leadUUID: leadUUID }
}
