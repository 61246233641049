'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { type Responsive, View } from 'reshaped'
import { useLeadUUID } from '../hooks/useLeadUUID'
import type { ResizeEvent } from './IframeResizeScript'

type ResponsiveIframeProps = {
  src: string
  title: string
  tags?: string
  initialHeight?: Responsive<string | number>
}

/**
 * This component is used to wrap the iframe so that it can be resized
 * when the iframe sends a resize event to the parent window.
 */
export const ResponsiveIframe: React.FC<ResponsiveIframeProps> = ({
  src,
  tags,
  title,
  initialHeight,
}) => {
  // We need to generate a unique ID for the iframe so we can listen
  // for the resize event and update the height of the specific iframe.
  const id = useMemo(() => window.crypto.randomUUID(), [])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeHeight, setIframeHeight] = useState<number | string>(0)
  const { leadUUID } = useLeadUUID('<%= it.cnfAppBaseUrl() %>')

  const handleMessage = useCallback(
    (event: MessageEvent<ResizeEvent>) => {
      if (event.data.type === 'resize' && event.data.id === id) {
        setIframeHeight(`${event.data.height}px`)
      }
    },
    [id],
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  const srcURL = useMemo(() => {
    const srcWithSlash = src.startsWith('/') ? src : `/${src}`
    const url = new URL(`<%= it.cnfAppBaseUrl() %>${srcWithSlash}`)

    if (tags) {
      const tagsSearchParams = new URLSearchParams(tags)

      tagsSearchParams.forEach((value, key) => {
        if (key.startsWith('tag.') && value) {
          url.searchParams.set(key, value)
          // supporting the use of partner page pre populated fields
        } else if (key.startsWith('app.') && value) {
          url.searchParams.set(key, value)
        } else {
          console.error(`Invalid client tag provided: ${key}`)
        }
      })
    }

    url.searchParams.set('lead-uuid', leadUUID)
    url.searchParams.set('iframeId', id)

    return url.toString()
  }, [tags, src, id, leadUUID])

  return (
    <View height={iframeHeight !== 0 ? 'auto' : initialHeight}>
      <iframe
        ref={iframeRef}
        title={title}
        id={id}
        src={srcURL}
        width="100%"
        height={iframeHeight}
        style={{
          border: 0,
        }}
      />
    </View>
  )
}
