import { useState } from 'react'
import { getCookie, setCookie } from './cookieService'

export function useCookie(key: string, defaultValue = '') {
  const [cookie] = useState<string>(() => {
    const cookieValue = getCookie(key)
    if (cookieValue) {
      return cookieValue
    }
    // Persist the default value if cookie not found
    setCookie(key, defaultValue)
    return defaultValue
  })

  return { cookie }
}
